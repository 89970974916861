<template>
    <strong>Termos e Condições</strong><br>
    <p>Estes termos e condições se aplicam ao aplicativo Devocional Diário Evangélico (referido como "Aplicativo") para dispositivos móveis, criado pela Quiz &amp; Jogos Online (referido como "Provedor de Serviços") como um serviço Freemium.</p>
    <br>
    <p>Ao baixar ou utilizar o Aplicativo, você concorda automaticamente com os seguintes termos. É altamente recomendado que você leia e compreenda estes termos antes de utilizar o Aplicativo. É estritamente proibida a cópia não autorizada, modificação do Aplicativo, qualquer parte do Aplicativo ou de nossas marcas registradas. Tentativas de extrair o código-fonte do Aplicativo, traduzi-lo para outros idiomas ou criar versões derivadas também não são permitidas. Todas as marcas registradas, direitos autorais, direitos de banco de dados e outros direitos de propriedade intelectual relacionados ao Aplicativo permanecem como propriedade do Provedor de Serviços.</p>
    <br>
    <p>O Provedor de Serviços está comprometido em garantir que o Aplicativo seja o mais útil e eficiente possível. Assim, reserva-se o direito de modificar o Aplicativo ou cobrar pelos seus serviços a qualquer momento e por qualquer motivo. O Provedor de Serviços assegura que quaisquer cobranças pelo Aplicativo ou seus serviços serão claramente comunicadas a você.</p>
    <br>
    <p>O Aplicativo armazena e processa dados pessoais que você forneceu ao Provedor de Serviços para fornecer o Serviço. É sua responsabilidade manter a segurança do seu telefone e o acesso ao Aplicativo. O Provedor de Serviços recomenda fortemente que você evite fazer jailbreak ou root em seu telefone, ações que envolvem a remoção de restrições de software impostas pelo sistema operacional oficial do dispositivo. Tais ações podem expor seu telefone a malwares, vírus, programas maliciosos, comprometer recursos de segurança do dispositivo e resultar no mau funcionamento ou na completa inoperância do Aplicativo.</p>
    <div>
    <p>O Aplicativo utiliza serviços de terceiros que possuem seus próprios Termos e Condições. Abaixo estão os links para os Termos e Condições dos provedores de serviços terceirizados utilizados pelo Aplicativo:</p>
    <ul>
        <li><a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Google Play Services</a></li>
        <li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li>
        <li><a href="https://www.google.com/analytics/terms/" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li>
        <li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li>
        <li><a href="https://onesignal.com/tos" target="_blank" rel="noopener noreferrer">One Signal</a></li>
        <li><a href="https://www.revenuecat.com/terms" target="_blank" rel="noopener noreferrer">RevenueCat</a></li>
    </ul>
    </div>
    <br>
    <p>O Provedor de Serviços não assume responsabilidade por determinados aspectos. Algumas funções do Aplicativo exigem uma conexão ativa com a internet, seja por Wi-Fi ou fornecida pelo seu provedor de rede móvel. O Provedor de Serviços não se responsabiliza caso o Aplicativo não funcione plenamente devido à falta de acesso ao Wi-Fi ou por você ter esgotado sua franquia de dados.</p>
    <br>
    <p>Se você usar o Aplicativo fora de uma área com Wi-Fi, esteja ciente de que os termos do contrato com seu provedor de rede móvel ainda se aplicam. Você pode incorrer em cobranças do provedor de rede móvel pelo uso de dados durante a conexão ao Aplicativo, ou outras cobranças de terceiros. Ao usar o Aplicativo, você aceita a responsabilidade por essas cobranças, incluindo tarifas de roaming se utilizar o Aplicativo fora de sua região ou país sem desativar o roaming de dados. Caso você não seja o responsável pela conta do dispositivo, presumimos que você obteve permissão do responsável.</p>
    <br>
    <p>Além disso, o Provedor de Serviços não pode sempre assumir responsabilidade pelo uso do Aplicativo. Por exemplo, é sua responsabilidade garantir que seu dispositivo esteja carregado. Se o dispositivo ficar sem bateria e você não conseguir acessar o Serviço, o Provedor de Serviços não será responsabilizado.</p>
    <br>
    <p>Em relação à responsabilidade do Provedor de Serviços pelo uso do Aplicativo, é importante notar que, embora ele se esforce para mantê-lo atualizado e preciso, depende de terceiros para fornecer informações que são disponibilizadas a você. O Provedor de Serviços não se responsabiliza por perdas, diretas ou indiretas, que você possa sofrer ao confiar totalmente nesta funcionalidade do Aplicativo.</p>
    <br>
    <p>O Provedor de Serviços pode atualizar o Aplicativo eventualmente. O Aplicativo está atualmente disponível conforme os requisitos do sistema operacional (e quaisquer sistemas adicionais aos quais eles decidam estender a disponibilidade do Aplicativo) podem mudar. Você precisará baixar as atualizações se quiser continuar usando o Aplicativo. O Provedor de Serviços não garante que sempre atualizará o Aplicativo para que seja relevante para você ou compatível com a versão do sistema operacional instalada no seu dispositivo. Contudo, você concorda em sempre aceitar as atualizações quando oferecidas. O Provedor de Serviços também pode decidir encerrar o fornecimento do Aplicativo e pode terminar seu uso a qualquer momento, sem aviso prévio. Salvo indicação em contrário, ao encerrar, (a) os direitos e licenças concedidos a você nestes termos serão encerrados; (b) você deve parar de usar o Aplicativo e, se necessário, excluí-lo do seu dispositivo.</p>
    <br><strong>Alterações nos Termos e Condições</strong>
    <p>O Provedor de Serviços pode atualizar periodicamente seus Termos e Condições. Assim, recomenda-se revisar esta página regularmente para verificar alterações. O Provedor de Serviços notificará sobre quaisquer alterações publicando os novos Termos e Condições nesta página.</p>
    <br>
    <p>Estes termos e condições são válidos a partir de 12 de janeiro de 2025.</p>
    <br><strong>Entre em Contato</strong>
    <p>Se você tiver dúvidas ou sugestões sobre os Termos e Condições, não hesite em contatar o Provedor de Serviços pelo e-mail: quizejogosonline@gmail.com.</p>
</template>

<script>
export default {
    name: "LeituraBiblicaDiariaTermsAndConditionsPT"
}
</script>